import Heading1 from "@/lib/Layout/Wrappers/Heading1";
import ImageWrapper from "@/lib/Layout/Wrappers/Image";
import Paragraph from "@/lib/Layout/Wrappers/Paragraph";
import Section from "@/lib/Layout/Wrappers/Section";
import React from "react";
import FadeIn from "@/lib/AnimationComponents/FadeIn";

const newsArticle = [
  {
    logoUrl: "https://assets.bonatra.com/package_detail/Entrepreneur.webp",
    alt: "Entrepreneur india Logo",
    link: "https://www.entrepreneur.com/en-in/news-and-trends/bonatra-raises-inr-55-crore-in-pre-seed-funding-round/439555",
  },
  {
    logoUrl:
      "https://assets.bonatra.com/package_detail/Times%20of%20india.webp",
    alt: "The weekly times of india Logo",
    link: "https://timesofindia.indiatimes.com/health-startup-bonatra-acquires-myava/articleshow/99940582.cms?from=mdr",
  },
  {
    logoUrl: "https://assets.bonatra.com/package_detail/Businessliner.webp",
    alt: "The Hindu businessline Logo",
    link: "https://www.thehindubusinessline.com/info-tech/mens-health-and-wellness-startup-bonatra-raises-55-crore-in-pre-seed-round/article66163975.ece",
  },
];

function Articles() {
  return (
    <Section className="flex flex-col items-center justify-center gap-4">
      <p className="text-center py-8 text-6xl font-semibold">
        Talk of the town
      </p>
      <Paragraph className="text-center text-lg">
        Bonatra is a unique interplay of hardware and software that seamlessly
        manages the glucose level in your body. It gives you real-time glucose
        readings, so you can make continuous atomic changes to improve your
        health and longevity.{" "}
      </Paragraph>
      <FadeIn duration={0.6} y={100}>
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-row flex-wrap justify-center items-center gap-12 py-10 px-10">
            {newsArticle?.map(({ logoUrl, alt,link }, id) => {
              return (
                <div
                  className="min-[1020px]:w-64 w-[10rem] h-12 md:h-24 relative"
                  key={id}
                >
                  <a href={link}>
                    <ImageWrapper alt={alt} src={logoUrl} className="" />
                  </a>
                </div>
              );
            })}
          </div>
          {/* <button className='border-2 border-black rounded-md w-50 p-2 px-16 my-10 mx-auto'>View more</button> */}
        </div>
      </FadeIn>
    </Section>
  );
}

export default Articles;
